export const DEFAULT_PAGE_SIZE = 15

export const PLAN_TYPES = {
  TEXT: { name: "文本", planValueContentLabel: "文字内容", planValueContentType: "TEXT", extra: false, extraName: "" },
  TEXTANDEXTRA: { name: "文本", planValueContentLabel: "文字内容", planValueContentType: "TEXT", extra: true, extraName: "扩展信息" },
  IMAGE: {
    name: "图片",
    planValueContentLabel: "图片地址",
    planValueContentType: "IMAGE",
    extra: false,
    extraName: "",
  },
  ITEM: {
    name: "跳转商品",
    planValueContentLabel: "商品 ID",
    planValueContentType: "ITEM",
    extra: false,
    extraName: "",
  },
  SEARCH: {
    name: "跳转搜索",
    planValueContentLabel: "关键词",
    planValueContentType: "TEXT",
    extra: false,
    extraName: "",
  },
  H5: {
    name: "Web",
    planValueContentLabel: "URL",
    planValueContentType: "TEXT",
    extra: false,
    extraName: "",
  },
  CATE: {
    name: "跳转类别",
    planValueContentLabel: "类别 ID",
    planValueContentType: "CATEGORY",
    extra: false,
    extraName: "",
  },
  HYBRID: {
    name: "Hybrid Web",
    planValueContentLabel: "URL",
    planValueContentType: "TEXT",
    extra: false,
    extraName: "",
  },
  USER_DETAIL: {
    name: "跳转店铺页",
    planValueContentLabel: "用户 ID",
    planValueContentType: "USER",
    extra: false,
    extraName: "用户名",
  },
  SCANNER: {
    name: "二维码扫描",
    planValueContentLabel: "URL",
    planValueContentType: "DISABLE",
    extra: false,
    extraName: "",
  },
  OFFLINE_ITEM: {
    name: "线下套餐",
    planValueContentLabel: "商品 ID",
    planValueContentType: "OFFLINE_ITEM",
    extra: false,
    extraName: "",
  },
  SECKILL_ITEM: {
    name: "秒杀商品",
    planValueContentLabel: "商品 ID",
    planValueContentType: "SECKILL_ITEM",
    extra: false,
    extraName: "",
  },
  STORE: {
    name: "到店店铺",
    planValueContentLabel: "店铺 ALIAS",
    planValueContentType: "STORE",
    extra: false,
    extraName: "",
  }
}
export const NOTIFICATION_TYPES = ["TEXT", "IMAGE", "ITEM", "CATE", "HYBRID", "USER_DETAIL", "OFFLINE_ITEM", "STORE", "SECKILL_ITEM"]
export const PLAN_POSITIONS = [
  {
    name: "启动页",
    key: "SPLASH",
    desc: "启动 APP 时的倒计时广告",
    allowTypes: ["ITEM", "H5", "CATE", "HYBRID", "USER_DETAIL"],
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "弹窗",
    key: "POPUP",
    desc: "APP 主界面中弹窗",
    allowTypes: ["ITEM", "H5", "CATE", "HYBRID", "USER_DETAIL"],
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "首页置顶",
    key: "BANNER",
    desc: "首页中间 Banner 中轮播",
    allowTypes: ["ITEM", "H5", "CATE", "HYBRID", "USER_DETAIL"],
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "瀑布流中",
    key: "WATERFALL",
    desc: "瀑布流中按照一定比例出现的广告",
    allowTypes: ["ITEM", "H5", "CATE", "HYBRID", "USER_DETAIL"],
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "类目页",
    key: "CATEGORY",
    desc: "类目页中的广告",
    allowTypes: ["ITEM"],
    disabled: true,
    allowImageUpload: false,
  },
  {
    name: "商品详情页",
    key: "DETAIL",
    desc: "商品详情页中的广告",
    allowTypes: ["ITEM"],
    disabled: true,
    allowImageUpload: false,
  },
  {
    name: "搜索默认词",
    key: "SEARCH_KEYWORD",
    desc: "点击搜索框后的界面上的可选择关键词",
    allowTypes: ["TEXT"],
    disabled: false,
    allowImageUpload: false,
  },
  {
    name: "搜索热词",
    key: "SEARCH_HOT",
    desc: "搜索框中的默认词, 如果有多个则随机",
    allowTypes: ["SEARCH"],
    disabled: false,
    allowImageUpload: false,
  },
  {
    name: "首页分类",
    key: "HOME_CATEGORY",
    desc: "首页展现的分类",
    allowTypes: ["CATE", "HYBRID", "H5"],
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "置顶商品",
    key: "HOME_ITEM",
    desc: "在首页中置顶推荐的商品",
    allowTypes: ["ITEM"],
    disabled: false,
    allowImageUpload: false,
  },
  {
    name: "分类置顶商品",
    key: "CATE_ITEM",
    desc: "在分类页中置顶推荐的商品",
    allowTypes: ["ITEM"],
    disabled: true,
    allowImageUpload: false,
  },
  {
    name: "'我的'额外链接",
    key: "ME_EXTRA_ITEM",
    desc: "在我的页面中出现的额外链接",
    allowTypes: ["H5", "HYBRID", "SCANNER"],
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "首页右上角",
    key: "HOME_TOP_RIGHT",
    desc: "首页右上角控制的链接",
    allowTypes: ["HYBRID"],
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "瀑布流轮播",
    key: "WATERFALL_CAROUSEL",
    desc: "瀑布流中轮播广告！选则图片比例17*25",
    allowTypes: ["ITEM", "H5", "CATE", "HYBRID", "USER_DETAIL"],
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "瀑布流运营位",
    key: "WATERFALL_AD",
    desc: "瀑布流中轮播旁广告位选则图片比例17*12",
    allowTypes: ["ITEM", "H5", "CATE", "HYBRID", "USER_DETAIL"],
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "团购白名单",
    key: "GROUPBUY_WHITELIST",
    desc: "团购白名单",
    allowTypes: ["USER_DETAIL"],
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "到店白名单",
    key: "STORE_WHITELIST",
    desc: "到店白名单",
    allowTypes: ["STORE"],
    disabled: false,
    allowImageUpload: false,
  },
  {
    name: "国货白名单",
    key: "GUOHUO_ZONE",
    desc: "国货白名单",
    allowTypes: ["USER_DETAIL"],
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "左侧小运营位",
    key: "HALF_LEFT",
    desc: "左侧小运营位",
    allowTypes: NOTIFICATION_TYPES,
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "右侧小运营位",
    key: "HALF_RIGHT",
    desc: "右侧小运营位",
    allowTypes: NOTIFICATION_TYPES,
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "活动页页面信息",
    key: "EVENT_PAGE",
    desc: "活动页页面信息",
    allowTypes: ["TEXTANDEXTRA"],
    disabled: false,
    allowImageUpload: true,
  }
].sort((p1, p2) => {
  return (p1.disabled | 0) - (p2.disabled | 0)
})

export const getPositionByName = position => {
  const r = PLAN_POSITIONS.filter(p => p.key == position)
  if (r.length == 1) {
    return r[0]
  } else {
    throw new Error("错误的运营位")
  }
}

export const HUMAN_PLAN_TYPE = type => {
  if (type >= PLAN_TYPES.length) {
    throw new Error("未知类型")
  }
  return PLAN_TYPES[type].name
}

export const PLAN_STATUS = {
  UNKNOWN: { name: "不特定" },
  ONLINE: { name: "上线" },
  CLOSED: { name: "下线" },
  PREPARE: { name: "排期中" },
}

export const STAT_TIME_UNIT = {
  QUARTERLY: {
    controlType: "datetimerange",
    label: "15分钟",
  },
  HOURLY: {
    controlType: "datetimerange",
    label: "小时",
  },
  DAILY: {
    controlType: "daterange",
    label: "日",
  },
  MONTHLY: {
    controlType: "daterange",
    label: "月",
  },
}

export const PROMO_TYPES = [
  {
    name: "减免类",
    key: "RELIEF",
    desc: "减免类的优惠券",
    allowTypes: ["ITEM", "H5"],
    disabled: false,
    allowImageUpload: true,
  },
  {
    name: "减免并且返现类",
    key: "RELIEF_CASHBACK",
    desc: "减免类的优惠券,同时增加特定用户（团长）返现行为",
    allowTypes: ["ITEM", "H5"],
    disabled: false,
    allowImageUpload: true,
  },
].sort((p1, p2) => {
  return (p1.disabled | 0) - (p2.disabled | 0)
})
