<template>
  <div v-title :data-title="compositeTitle">
    <Breadcrumb class="breadcrumb">
      <BreadcrumbItem to="/dashboard">U & Earth</BreadcrumbItem>
      <BreadcrumbItem v-for="(value, index) in links" :to="value.link" :key="index">
        {{ value.title }}
      </BreadcrumbItem>
    </Breadcrumb>
    <Divider style="margin: 5px 0 0" />
    <div class="mainContentWrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContentWrapper",
  components: {},
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    title: {
      default: "",
      type: String,
    },
  },
  data() {
    return {}
  },
  computed: {
    compositeTitle() {
      if (this.title) {
        return this.title
      }
      if (this.links && this.links.length) {
        return this.links[this.links.length - 1].title
      }
      return null
    },
  },
  watch: {},
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {},
  },
}
</script>

<style>
.breadcrumb {
  padding: 5px 10px;
}
.mainContentWrapper {
  padding: 10px;
}
</style>
