<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <Input
          v-model="queryForm.shipmentNo"
          type="text"
          placeholder="物流单号"
        />
      </FormItem>
      <FormItem>
        <Input
          v-model="queryForm.phone"
          type="text"
          placeholder="关联用户手机号"
        />
      </FormItem>
      <FormItem>
        <DatePicker
          v-model="dateRange"
          type="datetimerange"
          placement="bottom-end"
          placeholder="订单创建时间范围"
          style="width: 200px"
        ></DatePicker>
      </FormItem>
    </Form>
    <Table :data="items" :columns="tableColumns" style="width: 100%" />
    <Pager />
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import Pager from "@/components/Pager.vue"

export default {
  name: "ShipmentQueryPage",
  components: { ContentWrapper, Pager },
  data() {
    return {
      topLinks: [{ title: "物流管理", link: this.$route.path }],
      items: [],
      dateRange: ["", ""],
      queryForm: {},
      tableColumns: [
        { title: "物流单号" },
        { title: "物流公司" },
        { title: "订单号" },
        { title: "当前状态" },
        { title: "物流价格" },
        { title: "操作" },
      ],
    }
  },
  computed: {},
  watch: {},
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {},
  },
}
</script>
